import React from 'react';
import PropTypes from 'prop-types';
import Constants from 'constants/Constants';
import styled from 'styled-components';
import LoginStore from 'stores/LoginStore';
import EditableObjectHandle from '../Common/EditableObjectHandler';
import IntlObject from '../Common/IntlObject';

class Grid02 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authInfo: LoginStore.authInfo,
      options: this.props.siteSub.objects,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.setState({ authInfo: LoginStore.authInfo });
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onChange);
  }

  render() {
    const options = this.state.options;
    if (!options) {
      return null;
    }

    const isLogin = this.state.authInfo
      ? this.state.authInfo.status === Constants.AUTHED
      : false;

    const GridContainer = styled.section`
      position: relative;
      margin-top: 50px;
      margin-bottom: 50px;
    `;

    const FullWidthImage = styled.img`
      width: 100%;
      object-fit: cover; // This will cover the whole area without stretching the image.
    `;

    const TextContainer = styled.div`
      padding: 0 15px;
    `;

    const defaultObjects = {
      img: 'https://via.placeholder.com/600x400',
    };

    const Title = styled.div`
      color: #000;
      text-align: center;
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2em;
      letter-spacing: -0.8px;
    `;

    const Subtitle = styled.div`
      color: #8e8e8e;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24.4px;
      letter-spacing: -0.8px;
    `;

    return (
      <GridContainer className={this.props.containerClass}>
        <EditableObjectHandle
          id={this.props.siteSub.id}
          objects={options}
          defaultObjects={defaultObjects}
          isAdmin={isLogin}
          componentTitle="Grid02"
        />
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 mb-4">
            <Title>
              <IntlObject keycode="grid02.title" isLogin={isLogin} />
            </Title>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 mb-5">
            <Subtitle>
              <IntlObject keycode="grid02.subtitle" isLogin={isLogin} />
            </Subtitle>
          </div>
        </div>
        <div className="row no-gutters w-100">
          <div className="col-xl-6">
            <FullWidthImage
              src={options.img || defaultObjects.img}
              className="card-img-top img-fluid"
              alt="Editable"
            />
          </div>
          <div className="col-xl-6">
            <TextContainer>
              <p className="card-text">
                <IntlObject keycode="grid02.text.content" isLogin={isLogin} />
              </p>
            </TextContainer>
          </div>
        </div>
      </GridContainer>
    );
  }
}

Grid02.propTypes = {
  site: PropTypes.object.isRequired,
  siteSub: PropTypes.object.isRequired,
  containerClass: PropTypes.string,
};

export default Grid02;

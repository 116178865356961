import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Scrollspy from 'react-scrollspy';
import Constants from 'constants/Constants';
import LoginStore from 'stores/LoginStore';
import EditableObjectHandle from '../Common/EditableObjectHandler';
import { injectIntl } from 'react-intl';
import IntlObject from '../Common/IntlObject';

const StyledNavbar = styled.div`
  position: relative;

  & .navbar {
    background-color: white;
    transition: 0.3s;
  }

  & .navbar.scrolled {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
  }

  & .container {
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  & .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .logo {
    font-size: 24px;
    font-weight: bold;
  }

  & .menu {
    display: flex;
    margin-bottom: 0;
    margin-left: auto;
  }

  & .menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
  }

  & .menu li a {
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
    transition: 0.3s;
  }

  & .menu li.is-current a {
    color: #007bff;
  }

  & .menu li a:hover {
    color: #007bff;
  }
`;

const OnePageMenuType01 = ({ id, options }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [authInfo, setAuthInfo] = useState(LoginStore.authInfo);

  useEffect(() => {
    const onChange = () => {
      setAuthInfo(LoginStore.authInfo);
    };

    LoginStore.addChangeListener(onChange);
    return () => {
      LoginStore.removeChangeListener(onChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const isLogin = authInfo ? authInfo.status === Constants.AUTHED : false;

  const defaultObjects = {
    menuNumber: 3,
    menuId01: 'section1',
    menuId02: 'section2',
    menuId03: 'section3',
  };

  return (
    <StyledNavbar>
      <div className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
        <EditableObjectHandle
          id={id}
          objects={options}
          defaultObjects={defaultObjects}
          isAdmin={isLogin}
          componentTitle="OnePageMenuType01"
        />
        <div className="container">
          <div className="navbar-content w-100">
            <div className="logo">Logo</div>
            <Scrollspy
              items={['section1', 'section2', 'section3']}
              currentClassName="is-current"
              className="menu"
            >
              <li>
                <a href="#section1">Section 1</a>
              </li>
              <li>
                <a href="#section2">Section 2</a>
              </li>
              <li>
                <a href="#section3">Section 3</a>
              </li>
            </Scrollspy>
          </div>
        </div>
      </div>
    </StyledNavbar>
  );
};

export default injectIntl(OnePageMenuType01);

import React from 'react';
import PropTypes from 'prop-types';
import Constants from 'constants/Constants';
import styled from 'styled-components';
import LoginStore from 'stores/LoginStore';
import EditableObjectHandle from '../Common/EditableObjectHandler';
import IntlObject from '../Common/IntlObject';

class List2ColumnsType01 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authInfo: LoginStore.authInfo,
      options: this.props.siteSub.objects,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.setState({ authInfo: LoginStore.authInfo });
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onChange);
  }

  render() {
    const options = this.state.options;
    if (!options) {
      return null;
    }

    const isLogin = this.state.authInfo
      ? this.state.authInfo.status === Constants.AUTHED
      : false;

    const GridContainer = styled.section`
      position: relative;
      margin-top: 50px;
      margin-bottom: 50px;
      ${options['word-keep-all'] ? 'word-break: keep-all;' : ''}
      & .grid-card {
        box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.15);
      }

      & .card-body {
        padding: 28px 35px;
      }

      & .card-title {
        color: var(--b, #232323);
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.8px;
      }

      & .card-text {
        color: #66676e;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        letter-spacing: -0.7px;
      }
    `;

    const Title = styled.div`
      color: #000;
      text-align: center;
      font-size: 45px;
      font-style: normal;
      line-height: 1.2em;
      letter-spacing: -0.8px;
    `;

    const Subtitle = styled.div`
      color: #8e8e8e;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      line-height: 24.4px;
      letter-spacing: -0.8px;
    `;

    const defultObjects = {
      'word-keepall': '0',
      icon: '',
    };

    const icon = options.icon;

    const contents = options.contents || [];
    const keys = Object.keys(contents) || [];
    const splitIndex = Math.ceil(keys.length / 2);

    const leftKeys = keys.slice(0, splitIndex);
    const rightKeys = keys.slice(splitIndex, keys.length);

    const ContentsUL = styled.ul`
      list-style: none;
      padding-left: 0;
      & .list-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px; /* 리스트 항목 간의 간격을 50px로 설정 */
        font-size: 16px;
      }

      & .icon {
        flex-shrink: 0;
        margin-right: 10px;
      }

      & .text {
        flex-grow: 1;
      }
    `;

    return (
      <GridContainer className={this.props.containerClass}>
        <EditableObjectHandle
          id={this.props.siteSub.id}
          objects={this.state.options}
          defaultObjects={defultObjects}
          isAdmin={isLogin}
          componentTitle="List2ColumnsType01"
        />
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 mb-4">
            <Title>
              <IntlObject keycode="list2columns01.title" isLogin={isLogin} />
            </Title>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 mb-5">
            <Subtitle>
              <IntlObject keycode="list2columns01.subtitle" isLogin={isLogin} />
            </Subtitle>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <div className="row">
              <div className="col-12 col-xl-6 mb-4">
                <ContentsUL data-aos="fade-up">
                  <li className="list-item">
                    <img src={icon} alt="icon" className="icon" />
                    <IntlObject
                      keycode="list2columns01.contents.01"
                      isLogin={isLogin}
                    />
                  </li>
                  <li className="list-item">
                    <img src={icon} alt="icon" className="icon" />
                    <IntlObject
                      keycode="list2columns01.contents.02"
                      isLogin={isLogin}
                    />
                  </li>
                  <li className="list-item">
                    <img src={icon} alt="icon" className="icon" />
                    <IntlObject
                      keycode="list2columns01.contents.03"
                      isLogin={isLogin}
                    />
                  </li>
                  <li className="list-item">
                    <img src={icon} alt="icon" className="icon" />
                    <IntlObject
                      keycode="list2columns01.contents.04"
                      isLogin={isLogin}
                    />
                  </li>
                </ContentsUL>
              </div>
              <div className="col-12 col-xl-6 mb-4">
                <ContentsUL data-aos="fade-up" data-aos-delay="200">
                  <li className="list-item">
                    <img src={icon} alt="icon" className="icon" />
                    <IntlObject
                      keycode="list2columns01.contents.05"
                      isLogin={isLogin}
                    />
                  </li>
                  <li className="list-item">
                    <img src={icon} alt="icon" className="icon" />
                    <IntlObject
                      keycode="list2columns01.contents.06"
                      isLogin={isLogin}
                    />
                  </li>
                  <li className="list-item">
                    <img src={icon} alt="icon" className="icon" />
                    <IntlObject
                      keycode="list2columns01.contents.07"
                      isLogin={isLogin}
                    />
                  </li>
                  <li className="list-item">
                    <img src={icon} alt="icon" className="icon" />
                    <IntlObject
                      keycode="list2columns01.contents.08"
                      isLogin={isLogin}
                    />
                  </li>
                </ContentsUL>
              </div>
            </div>
          </div>
        </div>
      </GridContainer>
    );
  }
}

List2ColumnsType01.propTypes = {
  site: PropTypes.object.isRequired,
  siteSub: PropTypes.object.isRequired,
};

export default List2ColumnsType01;

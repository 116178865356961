import React from 'react';
import { injectIntl } from 'react-intl';
import PropertiesContainer from './PropertiesContainer';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import $ from 'jquery';
import _ from 'lodash';

class VirtualShowing01 extends React.Component {
  constructor(props) {
    super(props);

    this.getPropertiesContainer = this.getPropertiesContainer.bind(this);
  }

  getPropertiesContainer(type, options) {
    switch (type) {
      case '1':
        return <PropertiesContainer options={options} hasRibbon />;
      case '2':
        return <PropertiesContainer options={options} />;
      default:
        return <div></div>;
    }
  }

  componentDidMount() {
    const intl = this.props.intl;
    /*** 달력 생성하기 ***/
    var getDates = function (startDate, endDate) {
      var dates = [],
        currentDate = startDate,
        addDays = function (days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
      while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    };

    var weekday = [
      intl.formatMessage({ id: 'common.day.sunday' }),
      intl.formatMessage({ id: 'common.day.monday' }),
      intl.formatMessage({ id: 'common.day.tuesday' }),
      intl.formatMessage({ id: 'common.day.wednesday' }),
      intl.formatMessage({ id: 'common.day.thursday' }),
      intl.formatMessage({ id: 'common.day.friday' }),
      intl.formatMessage({ id: 'common.day.saturday' }),
    ];
    var dateNow = new Date();
    var dateTo = new Date(Date.now() + 13 * 86400000);
    var dates = getDates(dateNow, dateTo);

    if (!_.isEmpty(dates)) {
      dates.map((selectedDate) => {
        $('#bm-datepicker').append(`
        <span class="bm-date-container button-checkbox">
          <button type="button" class="btn" data-color="secondary">
            <p class="bm-date-footer">${weekday[selectedDate.getDay()]}</p>
            <p class="bm-date-title d-none">${selectedDate.getFullYear()}년 ${
          selectedDate.getMonth() + 1
        }${intl.formatMessage({ id: 'common.day.month' })}</p>
            <p class="bm-date-date">${selectedDate.getDate()}</p>
            <p class="bm-date-title">${selectedDate.getMonth() + 1}월</p>
          </button>
          <input type="checkbox" class="d-none" name="${selectedDate.toLocaleDateString()}" />
        </span>
      `);
      });
    }

    /*** 시간 생성하기 ***/

    var x = 30; //minutes interval
    var times = []; // time array
    var tt = 540; // start time
    var ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = tt % 60; // getting minutes of the hour in 0-55 format
      times[i] =
        ('0' + (hh % 12)).slice(-2) +
        ':' +
        ('0' + mm).slice(-2) +
        ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }

    if (!_.isEmpty(times)) {
      times.map((selectedTime) => {
        $('#bm-timepicker').append(`
        <span class="bm-time-container button-checkbox">
          <button type="button" class="btn" data-color="secondary">
            <p class="bm-time-title">${selectedTime}</p>
          </button>
          <input type="checkbox" class="d-none" name="${selectedTime}" />
        </span>
      `);
      });
    }

    $(function () {
      $('.button-checkbox').each(function () {
        var $widget = $(this),
          $button = $widget.find('button'),
          $checkbox = $widget.find('input:checkbox'),
          color = $button.data('color'),
          settings = {
            on: {
              icon: 'fa-check-circle',
            },
            off: {
              icon: 'fa-circle',
            },
          };

        // Event Handlers
        $button.on('click', function () {
          $checkbox.prop('checked', !$checkbox.is(':checked'));
          $checkbox.triggerHandler('change');
          updateDisplay();
        });
        $checkbox.on('change', function () {
          updateDisplay();
        });

        // Actions
        function updateDisplay() {
          var isChecked = $checkbox.is(':checked');

          // Set the button's state
          $button.data('state', isChecked ? 'on' : 'off');

          // Set the button's icon
          $button
            .find('.far')
            .removeClass()
            .addClass('far ' + settings[$button.data('state')].icon);

          // Update the button's color
          if (isChecked) {
            $button
              .removeClass('btn-outline-secondary')
              .addClass('btn-' + color + ' active');
          } else {
            $button
              .removeClass('btn-' + color + ' active')
              .addClass('btn-outline-secondary');
          }
        }

        // Initialization
        function init() {
          updateDisplay();

          // Inject the icon if applicable
          if ($button.find('.far').length == 0) {
            $button.prepend(
              '<i class="far ' +
                settings[$button.data('state')].icon +
                '"></i> '
            );
          }
        }
        init();
      });
    });

    window.$('#bm-datepicker').slick({
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    });

    window.$('#bm-timepicker').slick({
      slidesToShow: 6,
      slidesToScroll: 6,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    });
  }

  render() {
    const intl = this.props.intl;
    const VSContainer = styled.section`
      & input::placeholder {
        color: #ddd;
      }

      /* bm-date/time */
      @media (min-width: 993px) {
        & .bm-container {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      & .bm-date-container {
        padding: 5px;
      }

      & .bm-time-container {
        padding: 5px;
      }

      & .bm-date-border {
        border: 1px solid #ddd;
        width: 100%;
        height: 100%;
      }

      & .bm-date-container button,
      .bm-time-container button {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.125);
        font-size: 12px;
        padding: 3px;
      }

      & .bm-date-container p,
      .bm-time-container p {
        margin: 0;
      }

      & .bm-date-date {
        font-size: 25px;
        font-weight: bold;
        line-height: 1;
      }

      & .bm-date-title {
        font-size: 12px;
      }

      @media (max-width: 976px) {
        & .bm-date-title {
          font-size: 12px;
        }
      }

      .bm-date-footer {
        font-size: 12px;
      }

      & .slick-next {
        right: -25px;
      }

      & .slick-prev {
        left: -30px;
      }

      & .slick-prev:before,
      .slick-next:before {
        color: #666 !important;
        font-size: 25px;
      }

      & .bm-submit {
        font-size: 20px;
      }
    `;
    return (
      <VSContainer className={this.props.containerClass}>
        <form action={this.props.formEndpoint} method="post">
          {this.getPropertiesContainer(
            this.props.propertyType,
            this.props.options
          )}
          <div className="row bm-container">
            <div className="col-10 offset-1 col-md-8 offset-md-2">
              <h5 className="bm-subtitle mt-4 mb-3">
                {intl.formatMessage({ id: 'properties.label.02' })}
              </h5>
              <div id="bm-datepicker"></div>
            </div>
            <div className="col-10 offset-1 col-md-8 offset-md-2">
              <h5 className="bm-subtitle mt-4 mb-3">
                {intl.formatMessage({ id: 'properties.label.03' })}
              </h5>
              <div id="bm-timepicker"></div>
            </div>
          </div>
          <div className="row bm-container mb-4">
            <div className="col-md-8 offset-md-2">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="bm-subtitle mt-4 mb-3">
                    {intl.formatMessage({ id: 'properties.label.04' })}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-6 offset-md-3">
              <div className="row fw-light g-3">
                <div class="col-md-6 mb-2">
                  <label className="mb-1" for="bm-name">
                    {intl.formatMessage({ id: 'common.lastname' })} *
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="bm-first_name"
                    name="bm-first_name"
                    placeholder={intl.formatMessage({
                      id: 'common.name.placeholder',
                    })}
                    required
                  />
                </div>
                <div class="col-md-6 mb-2">
                  <label className="mb-1" for="bm-name">
                    {intl.formatMessage({ id: 'common.name' })} *
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="bm-last-name"
                    name="bm-last-name"
                    placeholder={intl.formatMessage({
                      id: 'common.name.placeholder',
                    })}
                    required
                  />
                </div>
                <div class="col-md-6 mb-2">
                  <label className="mb-1" for="bm-name">
                    {intl.formatMessage({ id: 'common.email' })} *
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="bm-email"
                    name="bm-email"
                    placeholder={intl.formatMessage({
                      id: 'common.email.placeholder',
                    })}
                    required
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <label className="mb-1" for="bm-phonenumber">
                    {intl.formatMessage({ id: 'common.phone' })} *
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="bm-phonenumber"
                    name="bm-phonenumber"
                    placeholder="123-456-7890"
                  />
                </div>
                <div className="form-group mb-2">
                  <label className="mb-1" for="bm-message">
                    메시지
                  </label>
                  <textarea
                    className="form-control form-control-sm"
                    id="bm-message"
                    style={{ height: '85px' }}
                    name="message"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-12">
              <button
                className="btn btn-sm btn-secondary border-0 profile-button bg-red mb-1"
                type="submit"
                style={{ height: '45px' }}
              >
                {intl.formatMessage({ id: 'properties.submit' })}
              </button>
            </div>
            <div className="col-md-12">
              <span className="vs-subtitle">
                {intl.formatMessage({ id: 'properties.submit.label' })}
              </span>
            </div>
            <div className="col-md-12" style={{ flexDirection: 'row' }}>
              <small
                style={{
                  textAlign: 'center',
                  width: '49%',
                }}
              >
                {intl.formatMessage({ id: 'properties.label.05' })}
              </small>
              <small>{'  |  '}</small>
              <small
                style={{
                  textAlign: 'center',
                  width: '49%',
                }}
              >
                <a href={`tel:${this.props.phone}`}>{this.props.phone}</a>
              </small>
            </div>
          </div>
        </form>
      </VSContainer>
    );
  }
}

VirtualShowing01.propTypes = {
  containerClass: PropTypes.string,
  phone: PropTypes.string,
  propertyType: PropTypes.string,
  formEndpoint: PropTypes.string,
};

VirtualShowing01.defaultProps = {
  containerClass: '',
  propertyType: '1',
  formEndPoint: '',
};

export default injectIntl(VirtualShowing01);

import React from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import Constants from 'constants/Constants';
import LoginStore from 'stores/LoginStore';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import EditableObjectHandle from '../Common/EditableObjectHandler';
import IntlObject from '../Common/IntlObject';

class HeaderType02 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brokerage: this.props.brokerage || {
        id: '',
        companyName: '',
        brokerName: '',
        address: '',
        phone: '',
        fax: '',
        logo: '',
        website: '',
      },
      authInfo: LoginStore.authInfo,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.setState({ authInfo: LoginStore.authInfo });
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onChange);
  }

  render() {
    const intl = this.props.intl;
    const TitleContainer = styled.section`
      position: relative;
      background-color: ${this.props.backgroundColor};
      position: relative;
      & .title-logo {
        max-height: 75px;
      }
      min-height: 30px;
    `;
    const Title = styled.h1`
      font-size: 50px;
      font-weight: 900;
      line-height: 1.18;
      color: ${(props) => `#${props.theme.colors.primaryColor}` || '#2e2f36'};
      margin: 0 0 5px 0;
      line-height: 1;
    `;

    const Subtitle = styled.h5`
      color: #888;
      font-weight: 400;
      line-height: 1.47;
      letter-spacing: -1.3px;
      font-size: 19px;
    `;

    const SNSLinks = styled.div`
      position: absolute;
      right: -12px;
      bottom: -6px;
      border-radius: 6px 0 0 6px;
      overflow: hidden;

      & a {
        color: #6f7075;
      }

      & .sns-container {
        width: 160px;
        transition: all 0.3s;
        cursor: pointer;
        align-items: center;
        font-size: 14px;
      }

      & .sns-container:hover {
        background-color: #ddd;
      }

      & .sns-name {
        width: 300px;
        text-align: left;
        font-weight: 400;
      }

      & .sns-button {
        margin-left: auto;
        padding-right: 12px !important;
        width: 60px;
      }

      @media (max-width: 752px) {
        & .sns-container {
          width: 40px;
          transition: all 0.3s;
          cursor: pointer;
        }
      }
    `;
    const sns = this.props.sns;

    const isLogin = this.state.authInfo
      ? this.state.authInfo.status === Constants.AUTHED
      : false;

    const defaultObjects = {
      companylogo: '',
    };

    const companylogo = this.props.options.companylogo || '';

    return (
      <TitleContainer className={this.props.containerClass}>
        <EditableObjectHandle
          id={this.props.id}
          objects={this.props.options}
          defaultObjects={defaultObjects}
          isAdmin={isLogin}
          componentTitle="HeaderType02"
        />
        {companylogo ? (
          <img src={companylogo} className="title-logo mb-5" alt="title-logo" />
        ) : (
          <img
            src={this.state.brokerage.logo}
            className="title-logo mb-5"
            alt="title-logo"
          />
        )}
        <Title className="fw-bold mb-2">
          <IntlObject
            keycode="headertype02.title"
            returnType="span"
            isLogin={isLogin}
          />
        </Title>
        <Subtitle>
          <IntlObject keycode="headertype02.subtitle" isLogin={isLogin} />
        </Subtitle>
        <SNSLinks>
          {sns.facebook && (
            <a href={sns.facebook} target="_blank" rel="noreferrer">
              <div className="d-flex bd-highlight sns-container">
                <div className="p-2 bd-highlight">
                  <img
                    src="https://img.icons8.com/color/20/000000/facebook.png"
                    alt="btn-facebook"
                  />
                </div>
                <div className="p-2 bd-highlight d-none d-sm-none d-md-block sns-name">
                  {intl.formatMessage({ id: 'common.facebook' })}
                </div>
                <div className="p-2 bd-highlight sns-button">
                  <img
                    className="d-none d-sm-none d-md-block"
                    src="https://img.icons8.com/ios-filled/15/B0B1B7/circled-chevron-right.png"
                    alt="circled-chevron-right"
                  />
                </div>
              </div>
            </a>
          )}
          {sns.instagram && (
            <a href={sns.instagram} target="_blank" rel="noreferrer">
              <div className="d-flex bd-highlight sns-container">
                <div className="p-2 bd-highlight">
                  <img
                    src="https://img.icons8.com/windows/20/DA525D/instagram-new.png"
                    alt="btn-instagram"
                  />
                </div>
                <div className="p-2 bd-highlight d-none d-sm-none d-md-block sns-name">
                  {intl.formatMessage({ id: 'common.instagram' })}
                </div>
                <div className="p-2 bd-highlight sns-button">
                  <img
                    className="d-none d-sm-none d-md-block"
                    src="https://img.icons8.com/ios-filled/15/B0B1B7/circled-chevron-right.png"
                    alt="circled-chevron-right"
                  />
                </div>
              </div>
            </a>
          )}
          {sns.youtube && (
            <a href={sns.youtube} target="_blank" rel="noreferrer">
              <div className="d-flex bd-highlight sns-container">
                <div className="p-2 bd-highlight">
                  <img
                    src="https://img.icons8.com/color/20/000000/youtube-play.png"
                    alt="btn-youtube"
                  />
                </div>
                <div className="p-2 bd-highlight d-none d-sm-none d-md-block sns-name">
                  {intl.formatMessage({ id: 'common.youtube' })}
                </div>
                <div className="p-2 bd-highlight sns-button">
                  <img
                    className="d-none d-sm-none d-md-block"
                    src="https://img.icons8.com/ios-filled/15/B0B1B7/circled-chevron-right.png"
                    alt="circled-chevron-right"
                  />
                </div>
              </div>
            </a>
          )}
          {sns.blog && (
            <a href={sns.blog} target="_blank" rel="noreferrer">
              <div className="d-flex bd-highlight sns-container">
                <div className="p-2 bd-highlight">
                  <img
                    src="https://img.icons8.com/ios/20/000000/blogger.png"
                    alt="btn-blog"
                  />
                </div>
                <div className="p-2 bd-highlight d-none d-sm-none d-md-block sns-name">
                  {intl.formatMessage({ id: 'common.blog' })}
                </div>
                <div className="p-2 bd-highlight sns-button">
                  <img
                    className="d-none d-sm-none d-md-block"
                    src="https://img.icons8.com/ios-filled/15/B0B1B7/circled-chevron-right.png"
                    alt="circled-chevron-right"
                  />
                </div>
              </div>
            </a>
          )}
        </SNSLinks>
      </TitleContainer>
    );
  }
}

HeaderType02.propTypes = {
  containerClass: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.object.isRequired,
  sns: PropTypes.shape({
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    youtube: PropTypes.string,
    blog: PropTypes.string,
  }),
};

HeaderType02.defaultProps = {
  containerClass: 'my-4 text-center',
  sns: {
    facebook: '',
    instagram: '',
    youtube: '',
    blog: '',
  },
};

export default injectIntl(HeaderType02);

import React, { useState, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { saveObjects } from 'services/CommonServices';

const EditableObjectHandle = (props) => {
  const { id, objects, defaultObjects, isAdmin, intl } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [editedObjects, setEditedObjects] = useState({ ...objects });

  useEffect(() => {
    // objects가 null, undefined 이거나, 객체인데 key가 없는 경우를 체크합니다.
    if (
      !objects ||
      (typeof objects === 'object' &&
        Object.keys(objects).length === 0 &&
        defaultObjects)
    ) {
      const saveDefaultObjects = async () => {
        const response = await saveObjects(id, defaultObjects);

        if (response.data.success) {
          window.location.reload();
        } else {
          alert(
            'Error saving default data: ' +
              (response.data.message || 'Unknown error'),
          );
        }
      };

      saveDefaultObjects();
    }
  }, [objects, defaultObjects, id]);

  const handleInputChange = useCallback((keyPath, value) => {
    setEditedObjects((prev) => {
      const newObj = { ...prev };
      const keys = keyPath.split('.');
      let current = newObj;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;
      return newObj;
    });
  }, []);

  const renderInput = (key, value, keyPath) => {
    if (typeof value === 'string') {
      if (key === 'pic') {
        return (
          <input
            type="url"
            className="form-control"
            value={value}
            onChange={(e) => handleInputChange(keyPath, e.target.value)}
          />
        );
      } else {
        return (
          <textarea
            className="form-control"
            value={value}
            onChange={(e) => handleInputChange(keyPath, e.target.value)}
          />
        );
      }
    } else if (typeof value === 'number') {
      // 숫자 타입을 확인하는 조건을 추가합니다.
      return (
        <input
          type="number"
          className="form-control"
          value={value}
          onChange={(e) =>
            handleInputChange(keyPath, parseFloat(e.target.value))
          }
        />
      );
    } else if (typeof value === 'object') {
      return Object.keys(value).map((subKey) =>
        renderInput(subKey, value[subKey], `${keyPath}.${subKey}`),
      );
    }
  };

  const handleSave = useCallback(async () => {
    const response = await saveObjects(id, editedObjects);

    if (response.data.success) {
      setIsEditing(false);
      window.location.reload();
    } else {
      alert('Error saving data: ' + (response.data.message || 'Unknown error'));
    }
  }, [id, objects, editedObjects]);

  const handleReset = useCallback(() => {
    setIsEditing(false);
    setEditedObjects({ ...objects });
  }, [objects]);

  const renderViewState = () => {
    return (
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => setIsEditing(true)}
      >
        {props.componentTitle
          ? `${props.componentTitle} ${intl.formatMessage({
              id: 'common.edit',
            })}`
          : intl.formatMessage({ id: 'common.edit' })}
      </button>
    );
  };

  const renderEditingState = () => (
    <form>
      <div className="container">
        {Object.keys(editedObjects).map((key) => (
          <div className="row" key={key}>
            <div className="col-12">
              <label className="form-label">{key}</label>
            </div>
            <div className="col-12">
              {renderInput(key, editedObjects[key], key)}
            </div>
          </div>
        ))}
        <hr />
        <div className="row">
          <div className="col text-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleReset}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  );

  const EditableObjectHandlerContainer = styled.div`
    text-align: left;
    ${isEditing ? 'position: fixed;' : 'position: absolute;'}
    top: 0;
    left: 0;
    padding: 0;
    overflow: auto;
    ${isEditing &&
    'bottom: 0; right: 0; width: 100%; background-color: rgba(255, 255, 255, 0.9); border: 1px solid #DDD; z-index: 1000;'}
    & input {
      font-size: 11px;
    }
    & .btn {
      font-size: 11px;
    }
    & label {
      font-size: 11px;
    }
  `;

  if (!isAdmin) return null;

  return (
    <EditableObjectHandlerContainer>
      {isEditing ? renderEditingState() : renderViewState()}
    </EditableObjectHandlerContainer>
  );
};

EditableObjectHandle.propTypes = {
  id: PropTypes.number.isRequired,
  objects: PropTypes.object.isRequired,
  defaultObjects: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  componentTitle: PropTypes.string,
};

export default injectIntl(EditableObjectHandle);

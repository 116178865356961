import axios from 'axios';

const API_URL = process.env.REACT_APP_API_SERVER_ENDPOINT;

// axios 인스턴스를 생성하여 모든 요청에 공통 구성 적용
const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 아래의 모든 API 함수는 api 인스턴스를 사용
const getTestData = () => api.get('/test');
const getSite = (memberId) => api.get('/get-site', { params: { memberId } });
const getSitePages = (memberId) =>
  api.get('/get-pages', { params: { memberId } });
const getSiteSubs = (pageId) =>
  api.get('/get-site-subs', { params: { pageId } });
const getConfirmedLatestYear = () => api.get('/get-confirmed-latest-year');
const getPropertySubs = (memberId, year, month) =>
  api.get('/get-property-subs', {
    params: { memberId, year, month },
  });
const getAuthorization = (email, password) =>
  api.post('/authenticate', { email, password });
const getMemberInfo = (memberId) =>
  api.get('/get-member-info', { params: { memberId } });
const updateMemberInfo = (memberId, data) =>
  api.post('/update-member-info', { data, memberId });
const saveHTMLContent = (id, style, script, html) =>
  api.post('/save-html-content', {
    id,
    style,
    script,
    html,
  });
const saveObjects = (id, objects) => api.post('/save-objects', { id, objects });
const getLocale = (langs = ['ko', 'en']) =>
  api.get('/getLocale', {
    params: {
      member_id: process.env.REACT_APP_MEMBERS_ID,
      langs,
    },
  });
const fetchLocale = (memberId, keycode) =>
  api.get('/fetch-locale', {
    params: { member_id: memberId, keycode },
  });
const saveLocale = (memberId, keycode, localeData) =>
  api.post('/save-locale', {
    member_id: memberId,
    keycode,
    localeData,
  });
const updateLocale = (member_id, code, ko, en) =>
  api.post('/update-locale', {
    member_id,
    code,
    ko,
    en,
  });
const saveSEO = (id, seo) => api.post('/save-seo', { id, seo });
const changePassword = (userId, oldPassword, newPassword) =>
  api.post('/change-password', {
    userId,
    oldPassword,
    newPassword,
  });
const getTheme = (themeId) => api.get(`/get-theme/${themeId}`);
const getAvailableThemes = () => api.get('/get-available-themes');
export const updateThemeForSite = (siteId, themeId) =>
  api.post('/update-theme-for-site', {
    siteId,
    themeId,
  });
export const saveEditorPage = (pageId, siteSubs) =>
  api.post('/save-editorpage', {
    pageId,
    siteSubs,
  });

export const deleteSiteSub = (id) => api.post('/delete-site-sub', { id });

// 모든 함수를 객체로 내보냄
export {
  getTestData,
  getSite,
  getSitePages,
  getSiteSubs,
  getConfirmedLatestYear,
  getPropertySubs,
  getAuthorization,
  getMemberInfo,
  updateMemberInfo,
  // writeFilePromise, // 프론트엔드에서 사용 불가능하므로 제외
  saveHTMLContent,
  saveObjects,
  getLocale,
  fetchLocale,
  saveLocale,
  updateLocale,
  saveSEO,
  changePassword,
  getTheme,
  getAvailableThemes,
};

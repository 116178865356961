import AppDispatcher from '../dispatcher/AppDispatcher';
import BaseStore from './BaseStore';
import Constants from '../constants/Constants';
import { setCookie, getCookie, removeCookie } from 'utils/CookieUtil';

class LoginStore extends BaseStore {
  constructor() {
    super();

    this._dispatchToken = AppDispatcher.register(
      this._registerToActions.bind(this),
    );
    this._loginModalOpen = false;
    this._signUpModalOpen = false;
    this._authInfo = getCookie('auth')
      ? getCookie('auth')
      : { status: 'UNAUTHED', data: {} };
    this._signupStatus = null;
    this._tokenFailed = false;
    this._signupEmail = null;
    this._passwordResetStatus = null;
    this._passwordResetEmail = null;
    this._changePasswordStatus = null;
    this._accountDereisterationStatus = null;
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case Constants.TOGGLE_LOGINMODAL:
        this._loginModalOpen = action.data;
        if (action.data) this._signUpModalOpen = false;
        if (!action.data) this._signupStatus = null;
        this.emitChange();
        break;
      case Constants.TOGGLE_SIGNUPMODAL:
        this._signUpModalOpen = action.data;
        this.emitChange();
        break;
      case Constants.ACCOUNT_DEREGISTERED:
        this._accountDereisterationStatus = Constants.ACCOUNT_DEREGISTERED;
        this.emitChange();
        break;
      case Constants.PASSWORD_CHANGED:
        this._changePasswordStatus = Constants.PASSWORD_CHANGED;
        this.emitChange();
        break;
      case Constants.PASSWORD_CHANGE_FAILED:
        this._changePasswordStatus = Constants.PASSWORD_CHANGE_FAILED;
        this.emitChange();
        break;
      case Constants.AUTHED:
        this._authInfo = {
          status: Constants.AUTHED,
          data: action.data.data,
        };

        setCookie('auth', this._authInfo, { path: '/' });

        if (this.authInfo.status === 'AUTHED') {
          this._loginModalOpen = false;
          this._signUpModalOpen = false;
        }

        this.emitChange();
        break;
      case Constants.USER_INFO_UPDATED:
        const updates = action.data.updates;
        const authInfo = this._authInfo;

        updates.forEach((update) => {
          authInfo.data[update.field] = update.value;
        });

        this._authInfo = authInfo;
        this._authInfo.status = Constants.AUTHED;

        setCookie('auth', this._authInfo, { path: '/' });

        this.emitChange();
        break;
      case Constants.NOT_AUTHED:
        this._authInfo = { status: Constants.NOT_AUTHED, data: {} };
        this.emitChange();
        break;
      case Constants.FB_NOT_AUTHED:
        this._authInfo = { status: Constants.FB_NOT_AUTHED, data: {} };
        this.emitChange();
        break;
      case Constants.LOG_OUT:
        this._authInfo = { status: 'UNAUTHED', data: {} };
        removeCookie('auth');
        // browserHistory.push('/');
        this.emitChange();
        break;
      case Constants.SIGNUP_SUCCESS:
        this._signupStatus = Constants.SIGNUP_SUCCESS;
        this._signupEmail = action.data.email;
        this.emitChange();
        break;
      case Constants.SIGNUP_FAILED:
        this._signupStatus = Constants.SIGNUP_FAILED;
        this.emitChange();
        break;
      case Constants.PASSWORD_RESET_SUCCESS:
        this._passwordResetStatus = Constants.PASSWORD_RESET_SUCCESS;
        this._passwordResetEmail = action.email;
        this.emitChange();
        break;
      case Constants.PASSWORD_RESET_FAILED:
        this._passwordResetStatus = Constants.PASSWORD_RESET_FAILED;
        this.emitChange();
        break;
      case Constants.PASSWORD_RESET_NOTCONFIRMED:
        this._passwordResetStatus = Constants.PASSWORD_RESET_NOTCONFIRMED;
        this.emitChange();
        break;
      case Constants.TOKEN_FAILED:
        this._tokenFailed = true;

        this._authInfo = undefined;
        setCookie('auth', { path: '/' });

        this.emitChange();
        break;
      default:
        break;
    }

    // Required by the dispatcher
    return true;
  }

  get loginModalOpen() {
    return this._loginModalOpen;
  }

  get signUpModalOpen() {
    return this._signUpModalOpen;
  }

  get authInfo() {
    return this._authInfo;
  }

  get status() {
    return this._authInfo ? this.authInfo.status : null;
  }

  get signUpStatus() {
    return this._signupStatus;
  }

  get fullName() {
    return this._authInfo.status === 'UNAUTHED'
      ? 'Guest'
      : `${this._authInfo.data.lastName} ${this._authInfo.data.firstName}`;
  }

  get fullNameWithoutDefault() {
    return this._authInfo.status === 'UNAUTHED'
      ? ''
      : `${this._authInfo.data.lastName} ${this._authInfo.data.firstName}`;
  }

  get tokenFailed() {
    const tokenFailed = this._tokenFailed;
    this._tokenFailed = false;
    return tokenFailed;
  }

  get signUpEmail() {
    return this._signupEmail;
  }

  get passwordResetStatus() {
    return this._passwordResetStatus;
  }

  get passwordResetEmail() {
    return this._passwordResetEmail;
  }

  get changePasswordStatus() {
    return this._changePasswordStatus;
  }

  get accountDereisterationStatus() {
    return this._accountDereisterationStatus;
  }

  get isAdmin() {
    if (!this._authInfo) return false;
    return this._authInfo.data.admin;
  }

  get isAssociatedAgent() {
    if (!this._authInfo) return false;
    return this._authInfo.data.userRoleId === 4;
  }
}

export default new LoginStore();

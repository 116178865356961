import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getMemberInfo } from '../../services/CommonServices';

class FooterType02 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      memberInfo: {},
    };
  }

  componentDidMount() {
    const memberId = process.env.REACT_APP_MEMBERS_ID;
    getMemberInfo(memberId)
      .then((response) => {
        this.setState({ memberInfo: response.data.memberInfo });
      })
      .catch((error) => {
        console.error('Error fetching member info: ', error);
      });
  }

  render() {
    const intl = this.props.intl;
    const FooterContainer = styled.section`
      font-size: 12px;
      & .bm-footer-logo {
        width: 50px;
      }

      @media (max-width: 767px) {
        & .bm-footer {
          flex-direction: column;
          text-align: left;
        }
      }
    `;

    const infoParts = [];
    const phoneNumber = this.state.memberInfo.phone;
    const email = this.state.memberInfo.email;
    const address = this.state.memberInfo.address;

    if (phoneNumber)
      infoParts.push(
        <span>
          <b>{intl.formatMessage({ id: 'footer.label.01' })}</b>
          {' ' + phoneNumber}
        </span>,
      );
    if (email) {
      if (infoParts.length > 0) infoParts.push(<span> | </span>);
      infoParts.push(
        <span>
          <b>{intl.formatMessage({ id: 'common.email' })}</b>
          {' ' + email}
        </span>,
      );
    }
    if (address) {
      if (infoParts.length > 0) infoParts.push(<span> | </span>);
      infoParts.push(
        <span>
          <b>{intl.formatMessage({ id: 'common.address' })}</b>
          {' ' + address}
        </span>,
      );
    }

    return (
      <FooterContainer className={this.props.containerClass}>
        <hr />
        <div
          className="bm-footer bm-flexbox-centered"
          style={{ flexDirection: 'row' }}
        >
          <span>{infoParts}</span>
          <p>
            App icon by{' '}
            <a href="http://www.icons8.com" target="_blank">
              icons8
            </a>
          </p>
        </div>
      </FooterContainer>
    );
  }
}

FooterType02.propTypes = {
  containerClass: PropTypes.string,
};

FooterType02.defaultProps = {
  containerClass: 'py-3 text-center',
};

export default injectIntl(FooterType02);

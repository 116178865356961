import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { saveEditorPage, deleteSiteSub } from '../../services/CommonServices';

const initialSiteSubTemplates = [
  {
    id: 'siteSub.header-01',
    type: 'siteSub.header-01',

    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.header-02',
    type: 'siteSub.header-02',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.html',
    type: 'siteSub.html',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.properties-01',
    type: 'siteSub.properties-01',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.properties-02',
    type: 'siteSub.properties-02',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.contactus',
    type: 'siteSub.contactus',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.virtualshowing-01',
    type: 'siteSub.virtualshowing-01',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.footer-01',
    type: 'siteSub.footer-01',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.footer-02',
    type: 'siteSub.footer-02',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.grid-01',
    type: 'siteSub.grid-01',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.grid-02',
    type: 'siteSub.grid-02',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.list-2column-01',
    type: 'siteSub.list-2column-01',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.onepage-menu-01',
    type: 'siteSub.onepage-menu-01',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
  {
    id: 'siteSub.left-fixed-menu-01',
    type: 'siteSub.left-fixed-menu-01',
    preview: 'https://i.ibb.co/VSGsh4p/header-01.png',
  },
];

const EditorPage = (props) => {
  const intl = props.intl;
  const [currentSiteSubs, setCurrentSiteSubs] = useState(props.siteSubs || []);
  const [siteSubTemplates, setSiteSubTemplates] = useState(
    initialSiteSubTemplates,
  );

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      destination.droppableId === 'currentSiteSubs'
    ) {
      const items = Array.from(currentSiteSubs);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      setCurrentSiteSubs(items);
    } else if (
      source.droppableId === 'siteSubTemplates' &&
      destination.droppableId === 'currentSiteSubs'
    ) {
      const itemToAdd = {
        ...siteSubTemplates[source.index],
        id: `template-${Date.now()}-${Math.random()}`,
      };
      setCurrentSiteSubs((prevCurrentSiteSubs) => [
        ...prevCurrentSiteSubs.slice(0, destination.index),
        itemToAdd,
        ...prevCurrentSiteSubs.slice(destination.index),
      ]);
    }
  };

  const handleSave = async () => {
    try {
      const orderedSiteSubs = currentSiteSubs.map((sub, index) => ({
        ...sub,
        order: index,
      }));

      const response = await saveEditorPage(props.pageId, orderedSiteSubs);

      if (response.data.success) {
        alert(response.data.message);
        window.location.reload();
      } else {
        alert(
          'Error saving data: ' + (response.data.message || 'Unknown error'),
        );
      }
    } catch (error) {
      alert('Error saving data: ' + error);
    }
  };

  const handleDelete = async (index, itemId) => {
    const isTemplateItem = itemId.toString().startsWith('template-');

    if (window.confirm('Are you sure you want to delete this item?')) {
      if (!isTemplateItem) {
        // DB에 존재하는 항목 삭제 요청
        try {
          await deleteSiteSub(itemId);
          alert('Item deleted successfully');
        } catch (error) {
          alert('Error deleting item: ' + error);
          return;
        }
      }
      // currentSiteSubs에서 항목 삭제
      setCurrentSiteSubs((prevCurrentSiteSubs) =>
        prevCurrentSiteSubs.filter((_, i) => i !== index),
      );
    }
  };

  const SaveButton = styled.button`
    float: right;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
  `;

  const DeleteButton = styled.button`
    float: right;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
  `;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="currentSiteSubs">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="row"
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? 'rgb(85, 130, 150, 0.5)'
                : 'rgb(255,255,255,0.1)',
              padding: 10,
            }}
          >
            <div className="col-9">
              <h4>Current Site Subs</h4>
            </div>
            <div className="col-3">
              <SaveButton onClick={handleSave}>Save</SaveButton>
            </div>
            {currentSiteSubs.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      userSelect: 'none',
                      padding: 16,
                      margin: '0 0 8px 0',
                      minHeight: '50px',
                      backgroundColor: 'rgb(255,255,255,0.8)',
                      color: 'black',
                      ...provided.draggableProps.style,
                    }}
                  >
                    {intl.formatMessage({ id: `${item.type}.title` })}
                    <DeleteButton
                      onClick={() => handleDelete(index, item.id)}
                      style={{ marginLeft: '10px' }}
                    >
                      Delete
                    </DeleteButton>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Droppable droppableId="siteSubTemplates">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="row"
            style={{
              backgroundColor: snapshot.isDraggingOver
                ? 'rgb(85, 130, 150, 0.5)'
                : 'rgb(255,255,255,0.1)',
              padding: 10,
              border: '3px solid #FFF',
              borderRadius: '10px',
              marginTop: '10px',
            }}
          >
            <h5>Site Sub Templates</h5>
            {siteSubTemplates.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="col-4"
                    style={{
                      userSelect: 'none',
                      padding: 16,
                      margin: '0 0 8px 0',
                      minHeight: '50px',
                      color: 'white',
                      ...provided.draggableProps.style,
                    }}
                  >
                    {intl.formatMessage({ id: `${item.type}.title` })}
                    <img src={item.preview} className="img-fluid" />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default injectIntl(EditorPage);

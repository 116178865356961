import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { fetchLocale, saveLocale } from '../../services/CommonServices';

function IntlObject({ keycode, returnType, isLogin, containerClass, containerStyle }) {
    const intl = useIntl();
    const [isEditing, setEditing] = useState(false);
    const [localeData, setLocaleData] = useState({ ko: '', en: '' });
    const member_id = process.env.REACT_APP_MEMBERS_ID;
    const koInputRef = useRef(null);
    const enInputRef = useRef(null);

    const fetchData = async () => {
        try {
            const res = await fetchLocale(member_id, keycode);
            if (res.data.success && res.data.messages) {
                const koMessage = res.data.messages.ko[keycode];
                const enMessage = res.data.messages.en[keycode];
                setLocaleData({ ko: koMessage, en: enMessage });
            } else {
                console.error('No messages found in response');
            }
        } catch (err) {
            console.error(err);
        }
    };

    const saveData = async () => {
        try {
            const koValue = koInputRef.current.value;
            const enValue = enInputRef.current.value;

            await saveLocale(member_id, keycode, { ko: koValue, en: enValue });
            setEditing(false);
            alert('Saved Successfully!');
            window.location.reload();
        } catch (err) {
            console.error(err);
            alert('Error saving data!');
        }
    };

    const onEdit = () => {
        if (!isLogin) return;  // 로그인이 되지 않은 상태에서는 아무런 동작도 하지 않습니다.
        fetchData();
        setEditing(true);
    };

    const ClickableContainer = styled.div`
        border: ${({ clickable }) => clickable ? '1px dashed lightgray' : 'none'};
        cursor: ${({ clickable }) => clickable ? 'pointer' : 'default'};

        &:hover {
            border: ${({ clickable }) => clickable ? '1px dashed gray' : 'none'};
            background-color: ${({ clickable }) => clickable ? 'rgba(220, 220, 220, 0.3)' : 'transparent'};
        }
    `;

    const fadeIn = keyframes`
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    `;

    const fadeOut = keyframes`
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    `;

    // 스타일 정의
    const FloatingLayer = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        animation: ${props => (props.visible ? fadeIn : fadeOut)} 0.5s forwards;
        z-index: 9999;
    `;

    const StyledForm = styled.div`
        width: 80%;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    `;

    const StyledLabel = styled.label`
        font-size: 13px;
        margin-right: 10px;
    `;

    const StyledInput = styled.input`
        width: 100%;
        font-size: 13px;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        border-radius: 5px;
    `;

    const StyledButton = styled.button`
        font-size: 13px;
        padding: 5px 15px;
        margin-right: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        background-color: #007bff;
        color: #fff;
        transition: background-color 0.3s;

        &:hover {
            background-color: #0056b3;
        }
    `;

    const FloatingInputLayer = ({ visible, localeData, saveData, setEditing, containerClass, containerStyle }) => (
        <FloatingLayer visible={visible}>
            <StyledForm className={containerClass} style={containerStyle}>
                <div>
                    <StyledLabel>KO:</StyledLabel>
                    <StyledInput defaultValue={localeData.ko} ref={koInputRef} />
                </div>
                <div>
                    <StyledLabel>EN:</StyledLabel>
                    <StyledInput defaultValue={localeData.en} ref={enInputRef} />
                </div>
                <StyledButton onClick={saveData}>Save</StyledButton>
                <StyledButton onClick={() => setEditing(false)}>Cancel</StyledButton>
            </StyledForm>
        </FloatingLayer>
    );

    const RenderComponent = () => {
        const message = intl.formatMessage({ id: keycode });

        const handleClick = () => {
            if (isLogin) {
                onEdit();
            }
        };

        const renderContent = (type) => {
            return (
                <ClickableContainer
                    as={type}
                    clickable={isLogin}
                    onClick={handleClick}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            );
        };

        return (
            <>
                {isEditing && (
                    <FloatingInputLayer
                        visible={isEditing}
                        localeData={localeData}
                        setLocaleData={setLocaleData}
                        saveData={saveData}
                        setEditing={setEditing}
                        containerClass={containerClass}
                        containerStyle={containerStyle}
                    />
                )}
                {(() => {
                    switch (returnType) {
                        case 'p':
                            return renderContent("p");
                        case 'span':
                            return renderContent("span");
                        case 'label':
                            return renderContent("label");
                        case 'div':
                        default:
                            return renderContent("div");
                    }
                })()}
            </>
        );
    };

    return <RenderComponent />;
}

IntlObject.propTypes = {
    keycode: PropTypes.string.isRequired,
    returnType: PropTypes.oneOf(['p', 'span', 'div', 'label']),
    isLogin: PropTypes.bool,
    containerClass: PropTypes.string,
    containerStyle: PropTypes.object,
};

IntlObject.defaultProps = {
    returnType: 'div',
    isLogin: false,
    containerClass: '',
    containerStyle: {},
};

export default IntlObject;

import React from 'react';
import styled from 'styled-components';

import LoginModal from './LoginModal';
import LogoutModal from './LogoutModal';

class CommonModules extends React.Component {
  render() {
    const HeaderContainer = styled.div`
      position: relative;
      margin: 0;
    `;

    return (
      <HeaderContainer>
        <LoginModal />
        <LogoutModal />
      </HeaderContainer>
    );
  }
}

export default CommonModules;

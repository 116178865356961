import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Constants from 'constants/Constants';
import LoginStore from 'stores/LoginStore';
import {
  saveSEO,
  getMemberInfo,
  updateMemberInfo,
  getAvailableThemes,
  updateThemeForSite,
} from '../../services/CommonServices';
import EditorPage from '../EditorPage';
import { injectIntl } from 'react-intl';

const SEOPanelContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? '0' : '-500px')};
  right: 0;
  max-width: 500px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
  transition: all 0.3s;
  padding-top: 60px;
  z-index: 1000;
  a & label {
    display: block;
  }
  & input {
    width: 100%;
    font-size: 0.7rem;
  }
`;

const SEOPanelContent = styled.div`
  display: block;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
`;

const ToggleButton = styled.button`
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #333;
  color: white;
  padding: 8px 16px;
  transition: all 0.3s;
  z-index: 1001;
`;

class SEOPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authInfo: LoginStore.authInfo,
      memberInfo: {
        name: '',
        e_name: '',
        njmls_name: '',
        phone: '',
        email: '',
        address: '',
        facebook: '',
        instagram: '',
        youtube: '',
        kakao: '',
        blog: '',
      },
      pageId: props.page.id,
      seo: props.page.seo,
      availableThemes: [],
      currentThemeId: props.site.themeId,
      isOpen: false,
      siteSubs: props.page.siteSubs,
    };

    this.state.seo.forEach((item) => {
      this.state[item.name] = item.content;
    });

    this.onChange = this.onChange.bind(this);
    this.togglePanel = this.togglePanel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSEOSave = this.handleSEOSave.bind(this);
    this.handleMemberInfoSave = this.handleMemberInfoSave.bind(this);
    this.handleThemeChange = this.handleThemeChange.bind(this);
    this.getCurrentThemeColor = this.getCurrentThemeColor.bind(this);
    this.handleThemeSelection = this.handleThemeSelection.bind(this);
  }

  onChange() {
    this.setState({ authInfo: LoginStore.authInfo });
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onChange);

    const memberId = process.env.REACT_APP_MEMBERS_ID; // Replace with actual member id

    getMemberInfo(memberId)
      .then((response) => {
        this.setState({ memberInfo: response.data.memberInfo });
      })
      .catch((error) => {
        console.error('Error fetching member info: ', error);
      });

    getAvailableThemes()
      .then((response) => {
        this.setState({ availableThemes: response.data.data });
      })
      .catch((error) => {
        console.error('Error fetching themes: ', error);
      });

    const currentThemeDetails = this.state.availableThemes.find(
      (theme) => theme.id === this.state.currentThemeId,
    );
    this.setState({ selectedThemeDetails: currentThemeDetails });
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onChange);
  }

  togglePanel = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleInputChange = (e, key, isMemberInfo) => {
    if (isMemberInfo) {
      this.setState((prevState) => ({
        memberInfo: {
          ...prevState.memberInfo,
          [key]: e.target.value,
        },
      }));
    } else {
      this.setState({ [key]: e.target.value });
    }
  };

  handleThemeChange = () => {
    const siteId = this.props.site.id;
    const newThemeId = this.state.currentThemeId;

    updateThemeForSite(siteId, newThemeId)
      .then((response) => {
        if (response.data.success) {
          this.setState({ currentThemeId: newThemeId });
          alert('Theme updated successfully');
          window.location.reload();
        } else {
          alert('Error updating theme: ' + response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error updating theme: ', error);
      });
  };

  handleSEOSave = () => {
    const seo = {
      title: this.state.title,
      description: this.state.description,
      keywords: this.state.keywords,
      favicon: this.state.favicon,
      og_title: this.state.og_title,
      og_image: this.state.og_image,
      og_site_name: this.state.og_site_name,
      og_description: this.state.og_description,
      og_url: this.state.og_url,
    };

    this.setState({ isOpen: false });

    saveSEO(this.state.pageId, seo)
      .then((response) => {
        if (response.data.success) {
          alert(response.data.message);
          window.location.reload();
        } else {
          alert(
            'Error saving data: ' + (response.data.message || 'Unknown error'),
          );
        }
      })
      .catch((err) => {
        alert('Error saving data: ' + err);
      });
  };

  getCurrentThemeColor() {
    const currentTheme = this.state.availableThemes.find(
      (theme) => theme.id === this.state.currentThemeId,
    );
    if (currentTheme) {
      return `#${currentTheme.colors.primaryColor}`;
    }
    return '#FFF'; // 기본 색상
  }

  handleThemeSelection = (e) => {
    const selectedThemeId = e.target.value;
    this.setState({ currentThemeId: selectedThemeId });
    const selectedTheme = this.state.availableThemes.find(
      (theme) => theme.id.toString() === selectedThemeId,
    );
    this.setState({ selectedThemeDetails: selectedTheme });
  };

  handleMemberInfoSave = () => {
    const memberId = process.env.REACT_APP_MEMBERS_ID; // Replace with actual member id
    const {
      name,
      e_name,
      njmls_name,
      phone,
      email,
      address,
      facebook,
      instagram,
      youtube,
      kakao,
      blog,
    } = this.state.memberInfo;

    updateMemberInfo(memberId, {
      name,
      e_name,
      njmls_name,
      phone,
      email,
      address,
      facebook,
      instagram,
      youtube,
      kakao,
      blog,
    })
      .then((response) => {
        if (response.data.success) {
          alert('Member info updated successfully');
          window.location.reload();
        } else {
          alert('Error updating member info: ' + response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error updating member info: ', error);
      });
  };

  render() {
    const intl = this.props.intl;
    const { isOpen } = this.state;
    const isLogin = this.state.authInfo
      ? this.state.authInfo.status === Constants.AUTHED
      : false;

    if (!isLogin) return null;

    return (
      <>
        <SEOPanelContainer isOpen={isOpen}>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-seo-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-seo"
                type="button"
                role="tab"
                aria-controls="nav-seo"
                aria-selected="true"
              >
                SEO
              </button>
              <button
                className="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Profile
              </button>
              <button
                className="nav-link"
                id="nav-editor-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-editor"
                type="button"
                role="tab"
                aria-controls="nav-theme"
                aria-selected="false"
              >
                Site Editor
              </button>
              <button
                className="nav-link"
                id="nav-theme-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-theme"
                type="button"
                role="tab"
                aria-controls="nav-theme"
                aria-selected="false"
              >
                Theme
              </button>
            </div>
          </nav>
          <div className="tab-content">
            <div
              className="tab-pane active"
              id="nav-seo"
              role="tabpanel"
              aria-labelledby="nav-seo-tab"
            >
              {this.state.seo.map((item, index) => (
                <SEOPanelContent key={index}>
                  <label>{item.name}: </label>
                  <input
                    type="text"
                    defaultValue={item.content}
                    onChange={(e) => this.handleInputChange(e, item.name)}
                  />
                </SEOPanelContent>
              ))}
              <hr />
              <SEOPanelContent className="d-grid gap-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleSEOSave}
                >
                  Save
                </button>
              </SEOPanelContent>
            </div>
            <div
              className="tab-pane"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <SEOPanelContent>
                <label>Name: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.name || ''}
                  onChange={(e) => this.handleInputChange(e, 'name', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>E Name: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.e_name || ''}
                  onChange={(e) => this.handleInputChange(e, 'e_name', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>NJMLS Name: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.njmls_name || ''}
                  onChange={(e) =>
                    this.handleInputChange(e, 'njmls_name', true)
                  }
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>Phone: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.phone || ''}
                  onChange={(e) => this.handleInputChange(e, 'phone', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>Email: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.email || ''}
                  onChange={(e) => this.handleInputChange(e, 'email', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>Address: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.address || ''}
                  onChange={(e) => this.handleInputChange(e, 'address', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>Facebook: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.facebook || ''}
                  onChange={(e) => this.handleInputChange(e, 'facebook', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>Instagram: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.instagram || ''}
                  onChange={(e) => this.handleInputChange(e, 'instagram', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>YouTube: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.youtube || ''}
                  onChange={(e) => this.handleInputChange(e, 'youtube', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>Kakao: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.kakao || ''}
                  onChange={(e) => this.handleInputChange(e, 'kakao', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent>
                <label>Blog: </label>
                <input
                  type="text"
                  value={this.state.memberInfo.blog || ''}
                  onChange={(e) => this.handleInputChange(e, 'blog', true)}
                />
              </SEOPanelContent>
              <SEOPanelContent className="d-grid gap-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleMemberInfoSave}
                >
                  Save Member Info
                </button>
              </SEOPanelContent>
            </div>
            <div
              className="tab-pane"
              id="nav-theme"
              role="tabpanel"
              aria-labelledby="nav-theme-tab"
            >
              <SEOPanelContent>
                <label>Choose Theme:</label>
                <select
                  style={{ backgroundColor: this.getCurrentThemeColor() }}
                  value={this.state.currentThemeId}
                  onChange={this.handleThemeSelection}
                >
                  {this.state.availableThemes.map((theme) => (
                    <option
                      value={theme.id}
                      key={theme.id}
                      style={{
                        backgroundColor: `#${theme.colors.primaryColor}`,
                      }}
                    >
                      {theme.themeName}
                    </option>
                  ))}
                </select>
              </SEOPanelContent>
              <SEOPanelContent className="d-grid gap-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleThemeChange}
                >
                  Save Theme
                </button>
              </SEOPanelContent>
              <hr />
              <SEOPanelContent>
                <div className="theme-details">
                  {this.state.selectedThemeDetails && (
                    <>
                      <div>
                        Primary Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.primaryColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #{this.state.selectedThemeDetails.colors.primaryColor}
                      </div>
                      <div>
                        Secondary Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.secondaryColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #{this.state.selectedThemeDetails.colors.secondaryColor}
                      </div>
                      <div>
                        Tertiary Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.tertiaryColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #{this.state.selectedThemeDetails.colors.tertiaryColor}
                      </div>
                      <div>
                        Quaternary Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.quaternaryColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #
                        {this.state.selectedThemeDetails.colors.quaternaryColor}
                      </div>
                      <div>
                        Body Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.bodyColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #{this.state.selectedThemeDetails.colors.bodyColor}
                      </div>
                      <div>
                        Text Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.textColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #{this.state.selectedThemeDetails.colors.textColor}
                      </div>
                      <div>
                        Light Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.lightColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #{this.state.selectedThemeDetails.colors.lightColor}
                      </div>
                      <div>
                        Dark Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.darkColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #{this.state.selectedThemeDetails.colors.darkColor}
                      </div>
                      <div>
                        Title Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.titleColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #{this.state.selectedThemeDetails.colors.titleColor}
                      </div>
                      <div>
                        Subtitle Color:
                        <span
                          style={{
                            backgroundColor: `#${this.state.selectedThemeDetails.colors.subtitleColor}`,
                            width: '20px',
                            height: '20px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            border: '1px solid #000',
                          }}
                        ></span>
                        #{this.state.selectedThemeDetails.colors.subtitleColor}
                      </div>
                      <div>
                        Font Name: {this.state.selectedThemeDetails.font.name}
                      </div>
                      <div>
                        Font URL:{' '}
                        <a
                          href={this.state.selectedThemeDetails.font.url}
                          target="_blank"
                        >
                          {this.state.selectedThemeDetails.font.url}
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </SEOPanelContent>
            </div>
            <div
              className="tab-pane"
              id="nav-editor"
              role="tabpanel"
              aria-labelledby="nav-theme-tab"
            >
              <SEOPanelContent>
                <EditorPage
                  siteSubs={this.state.siteSubs}
                  pageId={this.state.pageId}
                />
              </SEOPanelContent>
            </div>
          </div>
        </SEOPanelContainer>
        <ToggleButton onClick={this.togglePanel} isOpen={isOpen}>
          {intl.formatMessage({ id: 'common.seopanel' })}{' '}
          {isOpen
            ? intl.formatMessage({ id: 'common.close' })
            : intl.formatMessage({ id: 'common.open' })}
        </ToggleButton>
      </>
    );
  }
}

SEOPanel.propTypes = {
  id: PropTypes.string,
  seo: PropTypes.arrayOf(PropTypes.object),
  site: PropTypes.object,
};

export default injectIntl(SEOPanel);

import AppDispatcher from '../dispatcher/AppDispatcher';
import LoginService from '../services/LoginService';
import Constants from '../constants/Constants';
import $ from 'jquery';

class LoginAction {
  deregisterAccount(data) {
    LoginService.deregisterAccount(data).done((data) => {
      AppDispatcher.dispatch({
        actionType: Constants.ACCOUNT_DEREGISTERED,
        data,
      });
    });
  }

  changePassword(data) {
    LoginService.changePassword(data)
      .done((data) => {
        AppDispatcher.dispatch({
          actionType: Constants.PASSWORD_CHANGED,
        });
      })
      .fail((data) => {
        AppDispatcher.dispatch({
          actionType: Constants.PASSWORD_CHANGE_FAILED,
        });
      });
  }

  updateUserInfo(data) {
    LoginService.updateUserInfo(data).done((data) => {
      AppDispatcher.dispatch({
        actionType: Constants.AUTHED,
        data,
      });
    });
  }

  login(email, password) {
    LoginService.login(email, password)
      .then((data) => {
        AppDispatcher.dispatch({
          actionType: Constants.AUTHED,
          data,
        });
        window.$('#loginModal').modal('hide');
      })
      .catch((data) => {
        AppDispatcher.dispatch({
          actionType: Constants.NOT_AUTHED,
          data,
        });
      });
  }

  loginViaFB(fbId, email, firstName, lastName) {
    LoginService.loginViaFB(fbId, email, firstName, lastName)
      .done((data) => {
        AppDispatcher.dispatch({
          actionType: Constants.AUTHED,
          data,
        });
      })
      .fail((data) => {
        AppDispatcher.dispatch({
          actionType: Constants.FB_NOT_AUTHED,
          data,
        });
      });
  }

  logout() {
    AppDispatcher.dispatch({ actionType: Constants.LOG_OUT });
  }

  signup(data) {
    LoginService.signup(data)
      .done((data) => {
        AppDispatcher.dispatch({
          actionType: Constants.SIGNUP_SUCCESS,
          data,
        });
      })
      .fail((data) => {
        AppDispatcher.dispatch({
          actionType: Constants.SIGNUP_FAILED,
        });
      });
  }

  resetPassword(email) {
    LoginService.resetPassword(email)
      .done((data) => {
        AppDispatcher.dispatch({
          actionType: Constants.PASSWORD_RESET_SUCCESS,
          email: data.email,
        });
      })
      .fail((data) => {
        if (data.status == 500) {
          AppDispatcher.dispatch({
            actionType: Constants.PASSWORD_RESET_NOTCONFIRMED,
          });
        } else {
          AppDispatcher.dispatch({
            actionType: Constants.PASSWORD_RESET_FAILED,
          });
        }
      });
  }

  toggleLoginModal(status) {
    AppDispatcher.dispatch({
      actionType: Constants.TOGGLE_LOGINMODAL,
      data: status,
    });
  }

  toggleSignUpModal(status) {
    AppDispatcher.dispatch({
      actionType: Constants.TOGGLE_SIGNUPMODAL,
      data: status,
    });
  }
}

export default new LoginAction();

import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './index.css';
import App from './components/App';
import NotFound from './components/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';
import CommonModules from './components/CommonModules';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import { getLocale } from './services/CommonServices';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

function Root() {
  const [locale, setLocale] = useState(localStorage.getItem('lang') || 'ko');
  const [messages, setMessages] = useState({});
  const [availableLangs, setAvailableLangs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getLocale()
      .then((res) => {
        if (res.data.success) {
          setAvailableLangs(res.data.langs);
          setMessages(res.data.messages[locale]);
          setLoading(false);
        } else {
          console.error('Failed to load locale', res.data.message);
        }
      })
      .catch((err) => console.error(err));
  }, [locale]);

  const handleLocaleChange = (newLocale) => {
    setLocale(newLocale);
    localStorage.setItem('lang', newLocale);
    setMessages(messages[newLocale]);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <IntlProvider locale={locale} messages={messages}>
      <BrowserRouter>
        <Header />
        <CommonModules />
        <Switch>
          <Route exact path="/" component={App} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </IntlProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

reportWebVitals();

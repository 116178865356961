import React from 'react';
import LoginAction from 'actions/LoginAction';
import { changePassword } from '../../services/CommonServices';
import $ from 'jquery';

class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      success: null,
    };
    this.ref_email = React.createRef();
    this.ref_oldPassword = React.createRef();
    this.ref_newPassword = React.createRef();

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleSubmit(e);
    }
  };

  handleSubmit(e) {
    e.preventDefault();

    const email = this.ref_email.current.value;
    const oldPassword = this.ref_oldPassword.current.value;
    const newPassword = this.ref_newPassword.current.value;

    // 비밀번호 유효성 검사
    if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*()]).{6,}$/.test(newPassword)) {
      this.setState({
        error:
          '비밀번호는 6자 이상이어야 하며, 숫자, 영문자, 특수문자가 각각 1개 이상 포함되어야 합니다.',
      });
      return;
    }

    changePassword(email, oldPassword, newPassword)
      .then((response) => {
        // 성공 시의 처리 로직. 예를 들어, 사용자에게 성공 메시지를 표시합니다.
        window.location.reload();
      })
      .catch((error) => {
        // 실패 시의 처리 로직. 예를 들어, 사용자에게 오류 메시지를 표시합니다.
        console.error('Error changing password', error);

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // 서버로부터 받은 에러 메시지를 표시합니다.
          alert(error.response.data.message);
        } else {
          // 그 외의 경우, 일반적인 에러 메시지를 표시합니다.
          alert('Error changing password. Please try again later.');
        }
      });
  }

  render() {
    const { error, success } = this.state;

    return (
      <div id="changePasswordModal" className="modal fade" aria-hidden="true">
        <div className="modal-dialog modal-login">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Change Password</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    ref={this.ref_email}
                    placeholder="Email address"
                    required
                  />
                  <label htmlFor="email">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="old-password"
                    ref={this.ref_oldPassword}
                    placeholder="Old Password"
                    required
                  />
                  <label htmlFor="old-password">Old Password</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="new-password"
                    ref={this.ref_newPassword}
                    placeholder="New Password"
                    required
                  />
                  <label htmlFor="new-password">New Password</label>
                </div>
                <input
                  type="submit"
                  className="w-100 mb-2 btn btn-lg btn-primary"
                  value="Change Password"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class LoginModal extends React.Component {
  constructor(props) {
    super(props);

    this.ref_email = React.createRef();
    this.ref_password = React.createRef();
    this.clearInputs = this.clearInputs.bind(this);
  }

  clearInputs() {
    this.ref_email.current.value = '';
    this.ref_password.current.value = '';
  }

  handleSubmit(e) {
    e.preventDefault();
    const email = this.ref_email.current.value;
    const password = this.ref_password.current.value;

    LoginAction.login(email, password);
  }

  render() {
    return (
      <div>
        <div id="loginModal" className="modal fade" aria-hidden="true">
          <div className="modal-dialog modal-login">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Sign In</h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control rounded-4"
                    id="login-email"
                    ref={this.ref_email}
                    placeholder="name@example.com"
                  />
                  <label htmlFor="login-id">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control rounded-4"
                    id="login-password"
                    ref={this.ref_password}
                    placeholder="Password"
                    onKeyDown={this.handleKeyDown} // 여기에 handleKeyDown 추가
                  />
                  <label htmlFor="login-password">Password</label>
                </div>
                <input
                  className="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                  onClick={(e) => this.handleSubmit(e)}
                  type="button"
                  value="Sign In"
                />
                <button
                  type="button"
                  className="w-100 mb-2 btn btn-lg rounded-4 btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#changePasswordModal"
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
        <ChangePasswordModal />
      </div>
    );
  }
}

export default LoginModal;

import React from 'react';
import PropTypes from 'prop-types';
import Constants from 'constants/Constants';
import LoginStore from 'stores/LoginStore';
import 'react-quill/dist/quill.snow.css'; // Import styles
import { saveHTMLContent } from 'services/CommonServices';


class HtmlContainer extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      html: props.html,
      style: props.style,
      script: props.script
    };

    this.state = {
      ...this.initialState,
      authInfo: LoginStore.authInfo,
      isEditing: false,
    };

    this.onChange = this.onChange.bind(this);
    this.toggleEditor = this.toggleEditor.bind(this);
    this.updateField = this.updateField.bind(this);
  }

  toggleEditor = () => {
    this.setState(prevState => ({ isEditing: !prevState.isEditing }));
  }

  updateField = (field, value) => {
    this.setState({ [field]: value });
  }

  handleSaveContent = async () => {
    try {
      const response = await saveHTMLContent(this.props.id, this.state.style, this.state.script, this.state.html);

      if (response.data.success) {
        alert(response.data.message);
        this.setState({ isEditing: false });
      } else {
        alert("Error saving data: " + (response.data.message || "Unknown error"));
      }
    } catch (error) {
      alert("Error saving data: " + error);
    }
  }

  cancelEditing = () => {
    this.setState({ ...this.initialState, isEditing: false });
  }

  renderEditor() {
    return (
      <>
        <h2>Style</h2>
        <textarea
          value={this.state.style}
          style={{ width: '100%', height: '300px' }}
          onChange={(e) => this.updateField('style', e.target.value)}
        />
        <h2>Script</h2>
        <textarea
          value={this.state.script}
          style={{ width: '100%', height: '300px' }}
          onChange={(e) => this.updateField('script', e.target.value)}
        />
        <h2>HTML</h2>
        <textarea
          value={this.state.html}
          style={{ width: '100%', height: '300px' }}
          onChange={(e) => this.updateField('html', e.target.value)}
        />
      </>
    );
  }

  onChange() {
    this.setState({ authInfo: LoginStore.authInfo });
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onChange);
  }

  render() {
    if (this.state.scripts) eval(this.state.scripts);

    const isLogin = this.state.authInfo
      ? this.state.authInfo.status === Constants.AUTHED
      : false;

    return (
      <section className={this.props.containerClass}>
        <div
          dangerouslySetInnerHTML={{
            __html: `<style>${this.state.style}</style>`,
          }}
        />
        {isLogin && !this.state.isEditing &&
          <button onClick={this.toggleEditor} className="btn btn-primary mr-2">Edit Content</button>
        }
        {isLogin && this.state.isEditing &&
          <button onClick={this.switchMode} className="btn btn-primary mr-2">
            Switch to {this.state.mode === 'wysiwyg' ? 'HTML' : 'WYSIWYG'} Mode
          </button>
        }
        {this.state.isEditing ? this.renderEditor() :
          <div
            className="event-content"
            style={{ width: '100%' }}
            dangerouslySetInnerHTML={{ __html: this.state.html }}
          />
        }
        {this.state.isEditing &&
          <>
            <button onClick={this.handleSaveContent} className="btn btn-primary mr-2">Save and Exit Editing</button>
            <button onClick={this.cancelEditing} className="btn btn-warning mr-2">Cancel Editing</button>
          </>
        }
      </section>
    );
  }
}

HtmlContainer.propTypes = {
  id: PropTypes.string,
  html: PropTypes.string,
  style: PropTypes.string,
  script: PropTypes.string,
  containerClass: PropTypes.string,
};

HtmlContainer.defaultProps = {
  containerClass: '',
};

export default HtmlContainer;

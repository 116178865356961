import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class FooterType01 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const intl = this.props.intl;
    const FooterContainer = styled.section`
      font-size: 12px;
      & .bm-footer-logo {
        width: 50px;
      }
    `;
    return (
      <FooterContainer className={this.props.containerClass}>
        <hr />
        <div
          className="bm-footer bm-flexbox-centered"
          style={{ flexDirection: 'row' }}
        >
          <img
            className="bm-footer-logo"
            src="https://bubanc.s3.amazonaws.com/bms/common/bubanc.svg"
            style={{ marginRight: '15px' }}
          />
          <span>
            {intl.formatMessage({ id: 'footer.label.01' })} 201.201.9883
            <span className="d-none d-sm-inline-block mx-1">{`|`}</span>
            <br className="d-block d-sm-none" />
            {intl.formatMessage({ id: 'common.email' })} info@theadjoa.com
            <span className="d-none d-sm-inline-block mx-1">| </span>
            <br className="d-block d-sm-none" />
            {intl.formatMessage({ id: 'common.address' })} 2160 N Central Rd
            #112 Fort Lee, NJ 07024
          </span>
          <p>
            App icon by{' '}
            <a href="http://www.icons8.com" target="_blank">
              icons8
            </a>
          </p>
        </div>
      </FooterContainer>
    );
  }
}

FooterType01.propTypes = {
  containerClass: PropTypes.string,
};

FooterType01.defaultProps = {
  containerClass: 'py-3 text-center',
};

export default injectIntl(FooterType01);

import $ from 'jquery';
import { getAuthorization } from 'services/CommonServices';

class LoginService {
  deregisterAccount(data) {
    return $.ajax({
      url: '/api/deregister',
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(data),
      cache: false,
    });
  }

  changePassword(data) {
    return $.ajax({
      url: '/api/change-password',
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(data),
      cache: false,
    });
  }

  updateUserInfo(data) {
    return $.ajax({
      url: '/api/update-user-info',
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(data),
      cache: false,
    });
  }

  login(email, password) {
    return getAuthorization(email, password);
  }

  loginViaFB(fbId, email, firstName, lastName) {
    return $.ajax({
      url: '/authenticate-fb',
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({
        fbId: fbId,
        email: email,
        firstName: firstName,
        lastName: lastName,
      }),
      cache: false,
    });
  }

  signup(data) {
    return $.ajax({
      url: '/new-account',
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(data),
      cache: false,
    });
  }

  resetPassword(email) {
    return $.ajax({
      url: '/reset-password',
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ email: email }),
      cache: false,
    });
  }
}

export default new LoginService();

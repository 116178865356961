import React from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';

class HeaderType01 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const intl = this.props.intl;
    const TitleContainer = styled.section`
      position: relative;
      background-color: ${this.props.backgroundColor};
      position: relative;
      & .title-logo {
        height: 75px;
      }
    `;
    const Title = styled.h1`
      font-size: 50px;
      font-weight: 900;
      line-height: 1.18;
      color: #2e2f36;
      margin: 0 0 5px 0;
      line-height: 1;
    `;

    const Subtitle = styled.h5`
      color: #888;
      font-weight: 400;
      line-height: 1.47;
      letter-spacing: -1.3px;
      font-size: 19px;
    `;

    const SNSLinks = styled.div`
      position: absolute;
      right: -12px;
      bottom: -6px;
      border-radius: 6px 0 0 6px;
      overflow: hidden;

      & a {
        color: #6f7075;
      }

      & .sns-container {
        width: 160px;
        transition: all 0.3s;
        cursor: pointer;
        align-items: center;
        font-size: 14px;
      }

      & .sns-container:hover {
        background-color: #ddd;
      }

      & .sns-name {
        width: 300px;
        text-align: left;
        font-weight: 400;
      }

      & .sns-button {
        margin-left: auto;
        padding-right: 12px !important;
        width: 60px;
      }

      @media (max-width: 752px) {
        & .sns-container {
          width: 40px;
          transition: all 0.3s;
          cursor: pointer;
        }
      }
    `;
    const sns = this.props.sns;
    return (
      <TitleContainer className={this.props.containerClass}>
        {this.props.logo && (
          <img
            src={this.props.logo}
            className="title-logo mb-5"
            alt="title-logo"
          />
        )}
        <Title className="fw-bold mb-2">{Parser(this.props.title)}</Title>
        <Subtitle>{Parser(this.props.subtitle)}</Subtitle>
        <SNSLinks>
          {sns.facebook && (
            <a href={sns.facebook} target="_blank" rel="noreferrer">
              <div className="d-flex bd-highlight sns-container">
                <div className="p-2 bd-highlight">
                  <img
                    src="https://img.icons8.com/color/20/000000/facebook.png"
                    alt="btn-facebook"
                  />
                </div>
                <div className="p-2 bd-highlight d-none d-sm-none d-md-block sns-name">
                  {intl.formatMessage({ id: 'common.facebook' })}
                </div>
                <div className="p-2 bd-highlight sns-button">
                  <img
                    className="d-none d-sm-none d-md-block"
                    src="https://img.icons8.com/ios-filled/15/B0B1B7/circled-chevron-right.png"
                    alt="circled-chevron-right"
                  />
                </div>
              </div>
            </a>
          )}
          {sns.instagram && (
            <a href={sns.instagram} target="_blank" rel="noreferrer">
              <div className="d-flex bd-highlight sns-container">
                <div className="p-2 bd-highlight">
                  <img
                    src="https://img.icons8.com/windows/20/DA525D/instagram-new.png"
                    alt="btn-instagram"
                  />
                </div>
                <div className="p-2 bd-highlight d-none d-sm-none d-md-block sns-name">
                  {intl.formatMessage({ id: 'common.instagram' })}
                </div>
                <div className="p-2 bd-highlight sns-button">
                  <img
                    className="d-none d-sm-none d-md-block"
                    src="https://img.icons8.com/ios-filled/15/B0B1B7/circled-chevron-right.png"
                    alt="circled-chevron-right"
                  />
                </div>
              </div>
            </a>
          )}
          {sns.youtube && (
            <a href={sns.youtube} target="_blank" rel="noreferrer">
              <div className="d-flex bd-highlight sns-container">
                <div className="p-2 bd-highlight">
                  <img
                    src="https://img.icons8.com/color/20/000000/youtube-play.png"
                    alt="btn-youtube"
                  />
                </div>
                <div className="p-2 bd-highlight d-none d-sm-none d-md-block sns-name">
                  {intl.formatMessage({ id: 'common.youtube' })}
                </div>
                <div className="p-2 bd-highlight sns-button">
                  <img
                    className="d-none d-sm-none d-md-block"
                    src="https://img.icons8.com/ios-filled/15/B0B1B7/circled-chevron-right.png"
                    alt="circled-chevron-right"
                  />
                </div>
              </div>
            </a>
          )}
        </SNSLinks>
      </TitleContainer>
    );
  }
}

HeaderType01.propTypes = {
  containerClass: PropTypes.string,
  logo: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sns: PropTypes.shape({
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    youtube: PropTypes.string,
  }),
};

HeaderType01.defaultProps = {
  containerClass: 'my-4 text-center',
  logo: '',
  title: '',
  subtitle: '',
  sns: {
    facebook: '',
    instagram: '',
    youtube: '',
  },
};

export default injectIntl(HeaderType01);

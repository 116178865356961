import React from 'react';
import styled from 'styled-components';
import LoginStore from 'stores/LoginStore';
import Constants from 'constants/Constants';
import { getLocale, writeFilePromise } from 'services/CommonServices';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = { authInfo: LoginStore.authInfo };

    this.onChange = this.onChange.bind(this);
    this.handleLocaleRefresh = this.handleLocaleRefresh.bind(this);
  }

  onChange() {
    this.setState({ authInfo: LoginStore.authInfo });
  }

  handleLocaleRefresh(e) {
    e.preventDefault();

    getLocale().then((result) => {
      const fs = require('fs');
      const langs = result.data.langs;
      const messages = result.data.messages;
      const dir = '../../locale';
      langs.map((lang) => {
        fs.writeFile(
          `${dir}/${Object.keys(lang)}.json`,
          JSON.stringify(messages[lang]),
          (error) => {
            console.log('File created successfully');
          }
        );
      });
    });
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onChange);
  }
  render() {
    const Navbar = styled.nav`
      height: 40px;
      z-index: 1;
    `;
    const LoginButton = styled.img`
      float: right;
      z-index: 1;
      cursor: pointer;
    `;
    const isLogin = this.state.authInfo
      ? this.state.authInfo.status === Constants.AUTHED
      : false;

    return (
      <Navbar className="navbar navbar-expand navbar-light bg-light">
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {isLogin ? (
                <li
                  className="nav-item dropdown"
                  aria-labelledby="navbarDropdown"
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="https://img.icons8.com/color/30/000000/administrative-tools.png" />
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(e) => this.handleLocaleRefresh(e)}
                        href="#"
                      >
                        언어데이터 새로고침
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#logoutModal"
                      >
                        로그아웃
                      </a>
                    </li>
                  </ul>
                </li>
              ) : (
                <li className="nav-item " aria-labelledby="navbarDropdown">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#loginModal"
                  >
                    <LoginButton src="https://img.icons8.com/small/30/AAAAAA/user-male-circle.png" />
                  </a>
                </li>
              )}
              <li className="nav-item" aria-labelledby="navbar">
                <a
                  className="nav-link"
                  href="#"
                  onClick={() => {
                    localStorage.setItem('lang', 'ko');
                    window.location.reload();
                  }}
                >
                  <img src="https://img.icons8.com/color/30/000000/south-korea-circular.png" />
                </a>
              </li>
              <li className="nav-item" aria-labelledby="navbar">
                <a
                  className="nav-link"
                  href="#"
                  onClick={() => {
                    localStorage.setItem('lang', 'en');
                    window.location.reload();
                  }}
                >
                  <img src="https://img.icons8.com/color/30/000000/usa-circular.png" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Navbar>
    );
  }
}
export default Header;

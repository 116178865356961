const keyMirror = require('keymirror');

export default keyMirror({
  SET_DEVICETYPE: null,
  NEIGHBORHOODS_LIST: null,
  SELECTEDNEIGHBORHOODS_LIST: null,
  SELECTEDSCHOOL_LIST: null,
  SET_PROPERTYTYPE: null,
  SET_SELECTEDVALUES: null,
  MAIN_ADS: null,
  MAIN_ADS_RENTAL: null,
  MAIN_ADS_SALES: null,
  ADS_REQUESTED: null,
  INQURIED: null,
  AUTHED: null,
  NOT_AUTHED: null,
  FB_NOT_AUTHED: null,
  LOG_OUT: null,
  SIGNUP_SUCCESS: null,
  SIGNUP_FAILED: null,
  PASSWORD_RESET_SUCCESS: null,
  PASSWORD_RESET_FAILED: null,
  PASSWORD_RESET_NOTCONFIRMED: null,
  ROUTE_TO_MAIN_PAGE: null,
  ROUTE_TO_LISTING_PAGE: null,
  ROUTE_TO_SUB_PAGE: null,
  LISTINGS_SEARCH: null,
  LISTING_FETCH: null,
  LISTING_FETCH_FAILED: null,
  LISTINGS_FETCH_BEGIN: null,
  LISTINGS_MAIN_FETCH_BEGIN: null,
  LISTINGS_MAIN2_FETCH_BEGIN: null,
  LISTINGS_LISTINGBANNER_FETCHED: null,
  CUSTOM_INQUIRIED: null,
  EMAIL_SHARED: null,
  USERS_LIST: null,
  ALL_LISTINGS: null,
  USER_ACTIVE_UPDATE: null,
  DOWNLOAD_CSV: null,
  DOWNLOAD_LISTING_CSV: null,
  LISTINGS_FETCH_CONT: null,
  LISTINGS_SIDE_ADS: null,
  LISTINGS_SIDE_ADS_BIG: null,
  NEW_LISTING_POSTED: null,
  NEW_LISTING_UPDATED: null,
  GOOGLE_MAP_INIT: null,
  BROKER_INFO: null,
  BROKER_CONTACTED: null,
  MY_LISTINGS_FETCH_BEGIN: null,
  MY_LISTINGS_FETCH_CONT: null,
  USER_INFO_UPDATED: null,
  PASSWORD_CHANGED: null,
  PASSWORD_CHANGE_FAILED: null,
  INSUFFICIENT_FUND: null,
  ACCOUNT_DEREGISTERED: null,
  ALL_SUB_ADS_FETCHED: null,
  SUB_AD_FETCHED: null,
  ALL_MAIN_ADS_FETCHED: null,
  FILE_LIST_FETCH: null,
  QUERY_PROCESSED: null,
  IMG_LOADED: null,
  IMG_LOADED_FAIL: null,
  COMMUNITY_FEACHED: null,
  MY_COMMUNITY_FETCH_BEGIN: null,
  COMMUNITY_DETAIL_FETCHED: null,
  SIDE_BANNERS_FETCHED: null,
  LANGUAGE_CHANGED: null,
  COMMENTS_FETCHED: null,
  COMMENT_POSTED: null,
  POSTING_FAILED: null,
  TOKEN_FAILED: null,
  ADD_NOTIFICATION: null,
  SHOW_DIALOG: null,
  CLOSE_DIALOG: null,
  BANNERS_FEACHED: null,
  BANNERS_CATEGORY_FEACHED: null,
  TOGGLE_SEARCHPOPUP: null,
  TOGGLE_LOGINMODAL: null,
  TOGGLE_SIGNUPMODAL: null,
  TOGGLE_INQUIRYMODAL: null,
  LISTING_PREVIEW_FETCHED: null,
  SEARCH_FETCH_BEGIN: null,
  SEARCH_FETCH_DONE: null,
  RECENT_SEARCH_FETCH: null,
  SET_NEIGHBORHOOD_KEYWORD: null,
  UPDATED_SEARCH_BANNER: null,
});
